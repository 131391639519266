import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Article from "./pages/Article";
import Careers from "./pages/Careers";
import Contactus from "./pages/Contactus";
import Event from "./pages/Event";
import Solution from "./pages/Solution";
import Template1 from "./pages/Template1";
import Template2 from "./pages/Template2";
import Template3 from "./pages/Template3";
import Template4 from "./pages/Template4";
import Template5 from "./pages/Template5";
import Template6 from "./pages/Template6";
import MainLayout from "./layout/MainLayout";
import Checkmarx from "./pages/Checkmarx";
import ELK from "./pages/ELK";
import Gitlab from "./pages/Gitlab";
import Huawei from "./pages/Huawei";
import Kong from "./pages/Kong";
import DevOps from "./pages/DevOps";
import DevSecOps from "./pages/DevSecOps";
import CloudNative from "./pages/CloudNative";
import AutomatedTest from "./pages/AutomatedTest";
import PerformanceTest from "./pages/PerformanceTest";
import Consulting from "./pages/Consulting";
import MessengerCustomerChat from "react-messenger-customer-chat";
const App = () => {
  return (
    <>
      <MessengerCustomerChat pageId="111736734012410" />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Checkmarx" element={<Checkmarx />} />
          <Route path="/Huawei" element={<Huawei />} />
          <Route path="/Gitlab" element={<Gitlab />} />
          <Route path="/Kong" element={<Kong />} />
          <Route path="/ELK" element={<ELK />} />
          <Route path="/Article" element={<Article />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Contactus" element={<Contactus />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/Solution" element={<Solution />} />
          <Route path="/DevOps" element={<DevOps />} />
          <Route path="/DevSecOps" element={<DevSecOps />} />
          <Route path="/CloudNative" element={<CloudNative />} />
          <Route path="/AutomatedTest" element={<AutomatedTest />} />
          <Route path="/PerformanceTest" element={<PerformanceTest />} />
          <Route path="/Consulting" element={<Consulting />} />
          <Route path="/Template1" element={<Template1 />} />
          <Route path="/Template2" element={<Template2 />} />
          <Route path="/Template3" element={<Template3 />} />
          <Route path="/Template4" element={<Template4 />} />
          <Route path="/Template5" element={<Template5 />} />
          <Route path="/Template6" element={<Template6 />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
