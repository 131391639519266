import React from "react";
import Scrollspy from "react-scrollspy";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ProductItem from "../component/solution";
import SolutionLink from "../component/SolutionLink";
const CloudNative = () => {
  const { hash } = useLocation();

  const DevOpsRef = useRef(null);
  const DevSecOpsRef = useRef(null);
  const CloudRef = useRef(null);
  const ConsultingRef = useRef(null);
  const PerformanceRef = useRef(null);
  const AutomatedRef = useRef(null);

  useEffect(() => {
    if (hash) {
      const allHash = [
        { id: "DevOps", ref: DevOpsRef },
        { id: "DevSecOps", ref: DevSecOpsRef },
        { id: "Cloud", ref: CloudRef },
        { id: "Consulting", ref: ConsultingRef },
        { id: "Performance", ref: PerformanceRef },
        { id: "Automated", ref: AutomatedRef },
      ];

      const focusHash = allHash.find(
        (item) => item.id === hash.replace("#", "")
      );

      if (focusHash) {
        focusHash.ref.current.scrollIntoView();
      }
    }
  }, [hash]);
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />
        <div
          className="row"
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9 py-3 px-0" style={{ zIndex: "9999" }}>
            <div className="py-5 bg-white" style={{ paddingInline: "70px" }}>
              <div className="text-center">
                <img
                  src="images/solution/solution3.jpg"
                  style={{
                    width: "100%",
                    maxWidth: "740px",
                    height: "400px",
                    objectFit: "cover",
                  }}
                  className="mb-5"
                />
              </div>

              <div>
                <h1 style={{ fontWeight: "bold" }}>Cloud-Native</h1>
                <p style={{ textIndent: "50px" }}>
                  Our consulting services are designed to help organizations
                  improve their business performance by providing them with
                  expert advice and guidance. We work closely with our clients
                  to understand their unique challenges and opportunities and
                  develop tailored solutions to address them. Our consulting
                  team consists of experienced professionals with a deep
                  understanding of industry best practices, trends, and emerging
                  technologies. We help organizations optimize their operations,
                  increase efficiency, reduce costs, and drive growth. Our
                  consulting services include strategy development, process
                  optimization, technology evaluation, project management, and
                  change management.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <SolutionLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudNative;
