import React from "react";
// import CompLink from "../component/CompLink";
// import EventComp from "../component/EventComp";
// import Events from "../json/Event";
import TemplateLink from "../component/TemplateLink";

const Template1 = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row"
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="pt-5">
              <div
                style={{
                  background: "white",
                  padding: "4rem",
                }}
              >
                <h1>เส้นทางสู่การเป็น Dev Ops เริ่มจาก 0 ตอนแรก</h1>
                <p>พฤษภาคม 03, 2565</p>
                <h1 className="text-center font-eng">
                  <b>
                    First time!!, I'm a{" "}
                    <span className="text-danger">
                      <u>beginner!!!</u>
                    </span>
                  </b>
                </h1>
                <p style={{ textIndent: "7%" }}>
                  สวัสดีครับทุกๆท่าน ผมนาย No name
                  อยากจะมาแชร์ประสบการณ์ฝึกฝนตัวเอง เพื่อที่จะได้กลายเป็น Dev
                  Ops ซึ่งไม่รู้ว่าเส้นทางของผมจะจบลงที่ตรงไหน แต่อย่างน้อยๆ
                  ผมก็ขอแชร์ ความรู้ที่ได้รับจากพี่ ๆ น้อง ๆ
                  ที่ช่วยสั่งสอนผมในฐานะ beginner
                  ถ้ามีตรงไหนที่ผิดพลาดหรือตกหล่น ขออภัยมา ณ ที่นี้ด้วยน้าาาา{" "}
                </p>
                <br></br>
                <h1 className="text-center">
                  <b>DevOps ทำความรู้จักกันก่อนนะ</b>
                </h1>
                <img
                  src="images/template1/DevOps.png"
                  className="text-center py-5"
                  alt="DevOps"
                  style={{
                    width: "100%",
                    margin: "auto",
                    paddingInline: "18%",
                  }}
                />
                <p style={{ textIndent: "7%" }}>
                  <i>
                    <b>DevOps</b> เป็นคำย่อที่เกิดจากคำว่า Development และ
                    Operation เข้าด้วยกัน ซึ่งมีความหมายโดยรวมคือ
                    กระบวนการของพัฒนาโปรแกรมแบบครบวงจร ตั้งแต่การวางแผน
                    ไปจนถึงการ deploy ตัวงานจริง ติดตามสถานะ
                    คุณภาพและประสิทธิภาพของงานนั้น ๆ ครับ
                    การทำงานในลักษณะนี้จะทำเป็นลูปวนกลับมาใหม่เรื่อย ๆ
                  </i>
                </p>
                <br></br>
                <p style={{ textIndent: "7%" }}>
                  <i>
                    นั่นหมายความว่า การทำงานของ DevOps มีขอบเขตที่ตายตัว
                    เพราะกระบวนการหลักๆ ก็จะมีลักษณะที่คล้ายเดิมเสมอตามรูป หลัก
                    ๆ แล้วการทำงานของ DevOps ก็คือ การนำโปรแกรมในฝั่ง Dev
                    ไปรันบนเซิร์ฟเวอร์ได้แบบปกติครับ เอาล่ะ เกริ่นกันพอสมควรแล้ว
                    เรามาเข้าเรื่องหลักการเลยดีกว่า
                  </i>
                </p>
                <img
                  src="images/template1/linux.png"
                  className="text-center py-5"
                  alt="linux"
                  style={{
                    width: "100%",
                    margin: "auto",
                    paddingInline: "18%",
                  }}
                />
                <p>
                  อย่างที่เกริ่นไปตอนต้น ถ้าพูดถึงการทำงานของ DevOps
                  ก็ต้องเรียนรู้เรื่องของ Server เป็นพื้นฐานด้วยเช่นกัน
                  หลักๆแล้วในหัวข้อนี้คือ การทำความเข้าใจการใช้คำสั่งของ Command
                  ของ Linux ขั้นพื้นฐาน
                </p>
                <br></br>
                <p>
                  เพื่อน ๆสามารถเรียนรู้ไปพร้อมกัน ด้วยการสร้าง Server Linux
                  ขึ้นมาหนึ่งตัว เพื่อลองเข้าไปฝึกพิมพ์คำสั่งต่าง ๆ
                  ได้โดยตัวที่ผมจะแนะนำนั้นคือ ระบบปฏิบัติการ Ubuntu แบบ Server
                  นะครับ อย่าเผลอไปโหลดตัว Desktop มาล่ะ{" "}
                  <a href="https://ubuntu.com/download" target="_blank">
                    ubuntu download
                  </a>
                </p>
                <br></br>
                <p>
                  ขั้นตอนต่อไป หลังจากที่เราโหลดไฟล์ Ubuntu server.iso
                  มาแล้วทำอย่างไงต่อ ไฟล์ .iso
                  เป็นนามสกุลของไฟล์ที่ใช้ลงระบบปปฏิบัติการของเครื่องคอมพิวเตอร์ครับ
                  ยกตัวอย่างเช่น windows10.iso
                  ถ้าใช้ไฟล์ดังกล่าวลงที่เครื่องคอมพิวเตอร์
                  เครื่องนั้นก็จะมีระบบปฏิบิติการเป็น Windows 10 ครับ
                  ดังนั้นเพื่อที่จะใช้งาน เครื่อง Server Ubuntu
                  เราจำเป็นต้องลงไฟล์ดังกล่าวกับเครื่องคอมพิวเตอร์เปล่า
                  ๆหนึ่งเครื่องครับ
                </p>
                <br></br>
                <p>
                  แต่ไม่ต้องห่วงถ้าเพื่อน ๆ
                  มีคอมพิวเตอร์อยู่เครื่องเดียวในการใช้งาน
                  เราก็มีโปรแกรมที่ช่วยให้เราสามารถลงระบบปฏิบัติการโดยไม่ต้องล้างเครื่องใหม่ซึ่ง
                  หลักๆมีอยู่ 3 วิธีดังนี้ครับ
                </p>
                <br></br>
                <p>
                  1.ใช้โปรแกรม VM หรือ Virtual Machines
                  โปรแกรมจำลองพื้นที่สำหรับลงระบบปฏิบัติการ
                  ซึ่งมีอยู่หลายค่ายให้เพื่อน ๆ ไปเลือกลองใช้ดู
                  ซึ่งผมแนะนำให้เพื่อน ๆ เลือกวิธีนี้ก่อนวิธีอื่นนะครับ
                  จะได้ฝึกพื้นฐานการลงระบบปฏิบัติการด้วย
                </p>
                <br></br>
                <p>
                  2.ใช้ Docker Container โปรแกรมที่มีลักษณะคล้ายกับ VM
                  แต่เบากว่า ซึ่งในเบื้องต้นสำหรับมือใหม่ผมยังไม่อยากให้ใช้
                  Docker ครับเพราะเดี๋ยวจะมีการสอน Docker ในบทต่อ ๆ ไปครับ
                </p>
                <br></br>
                <p>
                  3.ใช้ Cloud ระบบที่ปัจจุบันคงไม่มีใครไม่รู้จักกันแล้ว แม้ว่า
                  Cloud จะมีข้อดีมากมาย แต่ก็มีข้อเสียหลัก ๆ
                  สำหรับผู้เริ่มต้นอย่างพวกเราเลย นั้นคือ มันเสียตัง
                  แต่ถ้าใครสะดวกจะลองใช้ก็ได้นะครับ
                </p>
                <br></br>
                <p>
                  หลังจากที่เพื่อนลงระบบ Ubuntu จนสำเร็จเป็นที่เรียบร้อยแล้ว
                  เพื่อนสามารถลอง test คำสั่งพื้นฐานของ linux ได้เลย
                  ไม่ว่าจะเป็น cd ls cat และอื่น ๆ ซึ่งสามารถหาดูได้จาก{" "}
                  <a
                    href="https://opensource.com/article/17/7/20-sysadmin-commands"
                    target="_blank"
                  >
                    20 Linux commands should know
                  </a>
                </p>
                <br></br>
                <p>
                  หลังจากtestคำสั่งได้พอจุใจแล้วเรามาเข้าสู่ Workshop ของ Post
                  ในวันนี้กันเลยดีกว่าครับ
                </p>
                <br></br>
                <p>
                  1.ติดตั้ง Nginx server ใน ubuntu จุดประสงค์เพื่อเรียนรู้ว่า
                  nginx server คืออะไร ทำงานอย่างไง
                </p>
                <br></br>
                <p>
                  2.Install wordpress บนเครื่องนั้น โดนใช้ Nginx เป็น web server
                  แทน Apache จุดประสงค์เพื่อเรียนรู้ว่า wordpress คืออะไร
                  ทำงานยังไง config ยังไง
                </p>

                <a
                  style={{
                    fontSize: "12px",
                    color: "blue",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href="https://www.digitalocean.com/community/tutorials/how-to-install-wordpress-with-lemp-on-ubuntu-20-04"
                >
                  HINT
                </a>

                <br></br>
                <p>สำหรับการเรียนรู้ในสัปดาห์นี้ก็คงจะมีเท่านี้ครับ </p>

                <p>ผู้เขียน : Nos Arctica</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <TemplateLink />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template1;
