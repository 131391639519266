import React from "react";

const AutoSlide = ({ image, wid }) => {
  return (
    <div className="text-center px-2" style={{ background: "#F2F2F2" }}>
      <img
        src={image}
        alt="slide show"
        className={wid}
        style={{
          height: "300px",
          objectFit: "contain",
          width: "100%",
          maxWidth: { wid },
        }}
      />
    </div>
  );
};

export default AutoSlide;
