import React from "react";
import Scrollspy from "react-scrollspy";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ProductItem from "../component/solution";
import TechLink from "../component/SolutionLink";
const Solution = () => {
  const { hash } = useLocation();
  const DevOpsRef = useRef(null);
  const DevSecOpsRef = useRef(null);
  const CloudRef = useRef(null);
  const ConsultingRef = useRef(null);
  const PerformanceRef = useRef(null);
  const AutomatedRef = useRef(null);

  useEffect(() => {
    if (hash) {
      const allHash = [
        { id: "DevOps", ref: DevOpsRef },
        { id: "DevSecOps", ref: DevSecOpsRef },
        { id: "Cloud", ref: CloudRef },
        { id: "Consulting", ref: ConsultingRef },
        { id: "Performance", ref: PerformanceRef },
        { id: "Automated", ref: AutomatedRef },
      ];

      const focusHash = allHash.find(
        (item) => item.id === hash.replace("#", "")
      );

      if (focusHash) {
        focusHash.ref.current.scrollIntoView();
      }
    }
  }, [hash]);
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row"
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div
            className="col-lg-9 bg-white py-3 px-0"
            style={{ zIndex: "9999" }}
          >
            <div>
              <ProductItem
                image1="images/solution/solution1.jpg"
                header1="DevOps Solution"
                ref1={DevOpsRef}
                detail1="Performance testing services are
          designed to help organizations ensure
          their applications can handle high
          traffic loads and perform optimally
          under stress. Our experienced testing
          team uses industry-leading tools and
          methodologies to measure and analyze
          the performance of our clients'
          applications, identifying  bottlenecks
          and areas for improvement."
                image2="images/solution/solution2.jpg"
                header2="DevSecOps Solution"
                ref2={DevSecOpsRef}
                detail2="We work closely with our clients to
          implement industry best practices for
          security, including secure coding
          practices, access control management,
          and threat modeling. Our team of
          experienced security professionals
          provides guidance and support
          throughout the development process
          to ensure that all security requirements
          are met."
              />
            </div>
            <div
              style={{
                backgroundColor: "rgba(37, 39, 33, 1)",
                paddingBlockEnd: "50px",
                marginBlockStart: "80px",
              }}
            >
              <div className="mb-5">
                <div className="container pt-5">
                  <div className="position-relative text-white">
                    <img
                      src="images/solution/solution3.jpg"
                      alt="DevOps Solution"
                      style={{
                        width: "100%",
                        maxWidth: "850px",
                        objectFit: "cover",
                        height: "350px",
                      }}
                    />
                    <div
                      className="home-res-center-text top-0 end-0"
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                      }}
                    >
                      <h1
                        className="text-end top-0 pt-3 reveal fade-left"
                        style={{ textShadow: "0 0 3px white" }}
                        ref={CloudRef}
                      >
                        <b>Cloud-Native</b>
                      </h1>
                      <br></br>
                      <div
                        style={{
                          backgroundColor: "rgba(131, 133, 129, 0.9)",
                          padding: "25px",
                        }}
                      >
                        <p className="text-start" style={{ textIndent: "7%" }}>
                          Our consulting services are designed to help
                          organizations improve their business performance by
                          providing them with expert advice and guidance. We
                          work closely with our clients to understand their
                          unique challenges and opportunities and develop
                          tailored solutions to address them. Our consulting
                          team consists of experienced professionals with a deep
                          understanding
                        </p>
                        <br></br>
                        <div className="text-end">
                          <a href="#" className="solution-link">
                            READ MORE...
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-5">
                <div className="container pt-5">
                  <div className="position-relative text-white">
                    <div className="text-end">
                      <img
                        src="images/solution/solution4.jpg"
                        alt="Automated Test"
                        style={{
                          width: "100%",
                          maxWidth: "850px",
                          objectFit: "cover",
                          height: "350px",
                        }}
                      />
                    </div>
                    <div
                      className="home-res-center-text-2 top-0"
                      style={{
                        width: "100%",
                        maxWidth: "500px",
                      }}
                    >
                      <h1
                        className="textstart top-0 pt-3 reveal fade-right"
                        style={{
                          textShadow: "0 0 3px white",
                          textAlign: "center",
                        }}
                        ref={AutomatedRef}
                      >
                        <b>Automated Test</b>
                      </h1>

                      <br></br>
                      <div
                        style={{
                          backgroundColor: "rgba(131, 133, 129, 0.9)",
                          padding: "25px",
                        }}
                      >
                        <p className="text-start" style={{ textIndent: "7%" }}>
                          Automated testing services help organizations improve
                          their software testing process ses by auton mating
                          repe etitive and time-consuming testing tasks,
                          allowing teams to focus on more complex testing
                          scenarios. Our experienced testing team uses
                          industry-leading tools and frameworks to design,
                          develop, and execute automated test scripts that
                          simulate real-world scenarios and identify potential
                          issues.
                        </p>
                        <br></br>
                        <div className="text-end">
                          <a href="#" className="solution-link">
                            READ MORE...
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ProductItem
                image1="images/solution/solution5.jpg"
                header1="Performance Test"
                ref1={PerformanceRef}
                detail1="Performance testing services are
          designed to help organizations ensure
          their applications can handle high
          traffic loads and perform optimally
          under stress. Our experienced testing
          team uses industry-leading tools and
          methodologies to measure and analyze
          the performance of our clients'
          applications, identifying bottlenecks
          and areas for improvement."
                image2="images/solution/solution5.jpg"
                header2="Consulting"
                ref2={ConsultingRef}
                detail2="Our DevOps, DevSecOps, and cloud native consulting services are designed to help organizations accelerate their software development and delivery processes while ensuring security, scalability, and efficiency. Our experienced consultants work closely with our clients to understand their specific requirements and develop tailored solutions that align with their business goals."
              />
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlock: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <TechLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solution;
