const SearchList = [
  {
    label: "DevSecOps Services & Consulting",
    page: "/#introduce",
  },
  {
    label: "WHY CHOOSE ARCTICA?",
    page: "/#whychoose",
  },
  {
    label: "ARTICLE UPDATE",
    page: "/#articles",
  },
  {
    label: "ARCTICA SERVICE OFFERING",
    page: "/#service",
  },
  {
    label: "Tools & Technologies we use",
    page: "/#tools",
  },
  {
    label: "Our Certified",
    page: "/#tools",
  },
  {
    label: "SUPPORT",
    page: "/#support",
  },
  {
    label: "DevOps Solution",
    page: "/DevOps",
  },
  {
    label: "DevSecOps Solution",
    page: "/DevSecOps",
  },
  {
    label: "Cloud-Native",
    page: "/CloudNative",
  },
  {
    label: "Automated Test",
    page: "/AutomatedTest",
  },
  {
    label: "Performance Test",
    page: "/PerformanceTest",
  },
  {
    label: "Consulting",
    page: "/Consulting",
  },
  {
    label: "DevOps Solution",
    page: "/DevOps",
  },
  {
    label: "CheckMarkx",
    page: "/Checkmarx",
  },
  {
    label: "Huawei",
    page: "/Huawei",
  },
  {
    label: "GitLab",
    page: "/Gitlab",
  },
  {
    label: "Kong",
    page: "/Kong",
  },
  {
    label: "ELK",
    page: "/ELK",
  },
  {
    label: "เส้นทางสู่การเป็น Dev Ops เริ่มจาก 0 ตอนแรก",
    page: "/Template1",
  },
  {
    label: "DevSecOps and DevOps as Professional Services",
    page: "/Template2",
  },
  {
    label: "DevOps as a Professional Service",
    page: "/Template3",
  },
  {
    label: "DevSecOps Integration with DevOps as a Service",
    page: "/Template4",
  },
  {
    label: "Empower Your Business with ELK",
    page: "/Template5",
  },
  {
    label: "Professional DevOps and DevSecOps Services",
    page: "/Template6",
  },
  {
    label: "Event",
    page: "/Event",
  },
  {
    label: "Arctica Meetup’23",
    page: "/event?id=1#",
  },
  {
    label: "DigiTech Asean Thailand 2022 Booth E14",
    page: "/event?id=2#",
  },
  {
    label: "DevOps Engineer (18k - 80k)",
    page: "/Careers#DevOps",
  },
  {
    label: "Careers",
    page: "/Careers",
  },
  {
    label: "Marketing Admin (18k - 22k)",
    page: "/Careers#Marketing",
  },
  {
    label: "Benefit",
    page: "/Careers#Benefit",
  },
  {
    label: "ABOUT US",
    page: "/Contactus",
  },
];

export default SearchList;
