import React from "react";
import TechLink from "../component/TechLink";
const ELK = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row "
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="py-5 bg-white" style={{ paddingInline: "70px" }}>
              <div className="text-center">
                <img
                  src="/images/product/elk.png"
                  style={{ width: "100%", maxWidth: "440px" }}
                  className="mb-5"
                />
              </div>
              <div>
                <p>
                  ELK is an acronym that refers to a stack of three open-source
                  software products: Elasticsearch, Logstash, and Kibana.
                  Together, they form a powerful and versatile platform for log
                  management and analytics. Here's an overview of each component
                  and its features :
                </p>
                <ol style={{ marginInlineStart: "20px" }}>
                  <li>
                    Elasticsearch: It is a distributed, real-time search and
                    analytics engine. Key features include :
                  </li>
                  <ul style={{ marginInlineStart: "20px" }}>
                    <li>
                      Full-text search: Elasticsearch enables fast and accurate
                      full-text search across large volumes of data.
                    </li>
                    <li>
                      Distributed architecture: It is designed to scale
                      horizontally, allowing you to store and process massive
                      amounts of data across multiple nodes.
                    </li>
                    <li>
                      Near real-time data processing: Elasticsearch provides
                      near real-time indexing and search capabilities, allowing
                      you to analyze data as it arrives.
                    </li>
                    <li>
                      Document-oriented: Data is stored in the form of JSON
                      documents, providing flexibility in structuring and
                      querying the data.
                    </li>
                    <li>
                      Advanced search functionalities: Elasticsearch supports
                      complex search queries, aggregations, filtering, and
                      geospatial search.
                    </li>
                    <li>
                      High availability and fault tolerance: It offers built-in
                      replication and sharding mechanisms to ensure data
                      availability and fault tolerance.
                    </li>
                  </ul>
                  <li>
                    Logstash: It is a data collection and ingestion pipeline
                    tool. Its features include :
                  </li>
                  <ul style={{ marginInlineStart: "20px" }}>
                    <li>
                      Data ingestion: Logstash allows you to collect data from
                      various sources such as log files, databases, message
                      queues, and more.
                    </li>
                    <li>
                      Data transformation: It provides a wide range of filters
                      and plugins to parse, enrich, and transform data before
                      sending it to Elasticsearch or other outputs.
                    </li>
                    <li>
                      Extensibility: Logstash is highly extensible, allowing you
                      to create custom plugins to cater to specific data
                      processing needs.
                    </li>
                    <li>
                      Scalability: It can handle high-volume data streams and
                      can be easily scaled horizontally to accommodate increased
                      data loads.
                    </li>
                  </ul>
                  <li>
                    Kibana: It is a web-based data visualization and exploration
                    tool. Some of its features include :
                  </li>
                  <ul style={{ marginInlineStart: "20px" }}>
                    <li>
                      Data visualization: Kibana offers a rich set of
                      visualizations such as line charts, bar charts, maps, and
                      histograms to represent data in a meaningful way.
                    </li>
                    <li>
                      Dashboard creation: It allows you to create interactive
                      dashboards by combining multiple visualizations and adding
                      filters and controls.
                    </li>
                    <li>
                      Ad hoc querying: Kibana provides an intuitive interface
                      for constructing ad hoc queries and exploring data
                      interactively.
                    </li>
                    <li>
                      Centralized log management: You can use Kibana to search,
                      filter, and analyze logs stored in Elasticsearch, enabling
                      centralized log management and troubleshooting.
                    </li>
                    <li>
                      User management and access control: Kibana supports user
                      authentication and authorization, allowing you to control
                      access to data and dashboards.
                    </li>
                  </ul>
                </ol>
                <p>
                  The ELK stack is widely used for log analysis, monitoring, and
                  real-time analytics across various industries and use cases.
                  It provides a powerful set of tools for collecting,
                  processing, storing, and visualizing data.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <TechLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ELK;
