import React from "react";

const ProductItem = ({
  header1,
  header2,
  detail1,
  detail2,
  image1,
  image2,
  id1,
  id2,
  ref1,
  ref2,
}) => {
  return (
    <div>
      <div className="mb-5">
        <div className="container pt-5">
          <div className="position-relative">
            <img
              src={image1}
              alt={header1}
              style={{
                width: "100%",
                maxWidth: "850px",
                objectFit: "cover",
                height: "350px",
              }}
            />
            <div
              className="home-res-center-text top-0 end-0"
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            >
              <h1
                className="text-end top-0 pt-3 reveal fade-left"
                style={{ textShadow: "0 0 3px white" }}
                id={id1}
                ref={ref1}
              >
                <b>{header1}</b>
              </h1>
              <br></br>
              <div
                style={{
                  backgroundColor: "rgba(199, 222, 255, 0.95)",
                  padding: "25px",
                }}
              >
                <p className="text-start" style={{ textIndent: "7%" }}>
                  {detail1}
                </p>
                <br></br>
                <div className="text-end">
                  <a href="#" className="solution-link">
                    READ MORE...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <div className="container pt-5">
          <div className="position-relative">
            <div className="text-end">
              <img
                src={image2}
                alt={header2}
                style={{
                  width: "100%",
                  maxWidth: "850px",
                  objectFit: "cover",
                  height: "350px",
                }}
              />
            </div>
            <div
              className="home-res-center-text-2 top-0 start-0"
              style={{
                width: "100%",
                maxWidth: "500px",
              }}
            >
              <h1
                className="textstart top-0 pt-3 reveal fade-right "
                style={{ textShadow: "0 0 3px white", textAlign: "center" }}
                id={id2}
                ref={ref2}
              >
                <b>{header2}</b>
              </h1>

              <br></br>
              <div
                style={{
                  backgroundColor: "rgba(199, 222, 255, 0.95)",
                  padding: "25px",
                }}
              >
                <p style={{ textIndent: "7%" }} className="text-start">
                  {detail2}
                </p>
                <br></br>
                <div className="text-end">
                  <a href="#" className="solution-link">
                    READ MORE...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
