import React from "react";
import ProductItem from "../component/prod";
import Scrollspy from "react-scrollspy";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Products = () => {
  const { hash } = useLocation();

  const CheckMarxRef = useRef(null);
  const HuaweiRef = useRef(null);
  const GitLabRef = useRef(null);
  const KongRef = useRef(null);
  const ELKRef = useRef(null);

  useEffect(() => {
    if (hash) {
      const allHash = [
        { id: "CheckMarx", ref: CheckMarxRef },
        { id: "Huawei", ref: HuaweiRef },
        { id: "GitLab", ref: GitLabRef },
        { id: "Kong", ref: KongRef },
        { id: "ELK", ref: ELKRef },
      ];

      const focusHash = allHash.find(
        (item) => item.id === hash.replace("#", "")
      );

      if (focusHash) {
        focusHash.ref.current.scrollIntoView();
      }
    }
  }, [hash]);

  return (
    <div className="">
      <ProductItem
        header="CheckMarx"
        image="/images/product/product1.png"
        maxwid="700px"
        ref1={CheckMarxRef}
      >
        <div>
          <p>
            CheckMarx is a leading commercial static application security
            testing (SAST) tool designed to help businesses enhance the security
            of their software applications. With the rising prevalence of cyber
            threats and the need for robust application security, CheckMarx
            offers a comprehensive solution to identify and mitigate potential
            vulnerabilities in source code.
          </p>
          <p>
            By leveraging static code analysis, CheckMarx enables organizations
            to proactively identify security flaws early in the software
            development lifecycle. It supports a wide range of programming
            languages, including Java, C#, C/C++, Python, JavaScript, Ruby, and
            more. This versatility makes it suitable for diverse development
            environments, empowering businesses across various industries to
            secure their applications effectively.
          </p>
          <p>
            Using a set of advanced security rules and algorithms, CheckMarx
            meticulously scans the codebase to uncover potential vulnerabilities
            such as SQL injection, cross-site scripting (XSS), buffer overflow,
            and other common security issues. The tool provides detailed reports
            that highlight the identified vulnerabilities, categorizing them
            based on severity and providing clear insights into their location
            within the code.
          </p>
          <p>
            CheckMarx goes beyond just identifying vulnerabilities; it also
            offers comprehensive remediation guidance to developers. By
            providing actionable recommendations and best practices, CheckMarx
            empowers development teams to efficiently address security issues
            and improve the overall resilience of their applications.
          </p>
          <p>
            Integrating CheckMarx into the commercial software development
            process allows businesses to establish a proactive and
            security-focused approach. By identifying and resolving
            vulnerabilities early on, organizations can significantly reduce the
            risk of data breaches, financial losses, and reputational damage.
          </p>
          <p>
            CheckMarx can be seamlessly integrated into existing development
            environments, including popular integrated development environments
            (IDEs) and build automation systems. This integration ensures a
            smooth workflow for developers, enabling them to conveniently
            incorporate security testing as an integral part of their coding
            process.
          </p>
          <p>
            Overall, CheckMarx provides businesses with a powerful solution to
            enhance application security, reduce risks, and protect sensitive
            data. By leveraging its comprehensive scanning capabilities,
            actionable reporting, and guidance, organizations can prioritize
            security and build robust, resilient applications for commercial
            use.
          </p>
        </div>
      </ProductItem>

      <ProductItem
        header="Huawei"
        detail="รายละเอียด"
        image="/images/product/product2.png"
        maxwid="400px"
        ref1={HuaweiRef}
        reverse
      >
        <div>
          <p>
            Huawei Cloud is a cloud computing platform offered by Huawei
            Technologies, a leading global provider of information and
            communication technology (ICT) infrastructure and smart devices.
            Huawei Cloud provides a wide range of cloud-based services and
            solutions to individuals, businesses, and organizations. Here are
            some key features of Huawei Cloud :
          </p>
          <ol style={{ marginInlineStart: "20px" }}>
            <li>
              Computing Services: Huawei Cloud offers scalable and flexible
              computing resources, including virtual machines (VMs), bare metal
              servers, and container instances. Users can easily provision and
              manage computing resources to meet their specific requirements.
            </li>
            <li>
              Storage Services: Huawei Cloud provides various storage options,
              such as object storage, block storage, and file storage. These
              services enable users to store and manage their data efficiently
              and securely.
            </li>
            <li>
              Database Services: Huawei Cloud offers managed database services,
              including Relational Database Service (RDS) and Distributed
              Database Service (DDS). These services provide users with highly
              available and scalable database solutions.
            </li>
            <li>
              Networking Services: Huawei Cloud includes networking services
              such as Virtual Private Cloud (VPC) and Elastic Load Balance
              (ELB), which allow users to create isolated virtual networks and
              distribute network traffic across multiple instances.
            </li>
            <li>
              Security and Compliance: Huawei Cloud incorporates advanced
              security measures to protect user data and resources. It offers
              features like identity and access management, network isolation,
              and encryption to ensure data confidentiality and integrity.
            </li>
            <li>
              AI and Big Data Services: Huawei Cloud provides a range of AI and
              big data services, including machine learning, data analytics, and
              data warehousing. These services empower users to extract insights
              from their data and leverage artificial intelligence technologies.
            </li>
            <li>
              Internet of Things (IoT) Services: Huawei Cloud offers IoT
              services that enable users to connect, manage, and analyze data
              from IoT devices. It provides a platform for building IoT
              applications and managing device connectivity at scale.
            </li>
            <li>
              DevOps and Application Services: Huawei Cloud includes tools and
              services for application development, deployment, and management.
              It supports various programming languages, frameworks, and
              development environments.
            </li>
            <li>
              Hybrid Cloud Solutions: Huawei Cloud provides hybrid cloud
              solutions that allow users to integrate their on-premises
              infrastructure with the cloud. This enables seamless data
              migration, hybrid deployments, and hybrid management.
            </li>
            <li>
              Global Presence: Huawei Cloud has a global footprint with data
              centers located in multiple regions worldwide. This allows users
              to deploy their applications and services closer to their target
              audience, reducing latency and improving performance.
            </li>
          </ol>
        </div>
      </ProductItem>
      {/* <ProductItem
        header="DELL"
        image="/images/product/product3.png"
        maxwid="400px"
        id="DELL"
      ></ProductItem> */}

      <ProductItem
        header="GitLab"
        detail="รายละเอียด"
        image="/images/product/product4.png"
        maxwid="700px"
        id="GitLab"
        ref1={GitLabRef}
        reverse
      >
        <div>
          <p>
            GitLab is a web-based DevOps platform that provides a complete set
            of tools for software development, version control, collaboration,
            and continuous integration/continuous deployment (CI/CD). It is
            primarily used for managing and tracking source code repositories,
            but it also offers additional features to support the entire
            software development lifecycle.
          </p>
          <p>
            GitLab offers both cloud-based and self-hosted options, allowing
            organizations to choose the deployment model that best suits their
            needs. The self-hosted version enables companies to have complete
            control over their source code and data by hosting GitLab on their
            own infrastructure.
          </p>
          <p>Here are some key features and capabilities of GitLab :</p>
          <ol style={{ marginInlineStart: "20px" }}>
            <li>
              Version Control: GitLab is built on top of Git, a distributed
              version control system. It provides powerful Git repository
              management with features like branching, merging, and conflict
              resolution.
            </li>
            <li>
              Issue Tracking: GitLab includes an issue tracking system that
              allows teams to create, assign, and track tasks, bugs, and feature
              requests. It provides a centralized place for collaboration and
              discussion around issues.
            </li>
            <li>
              Continuous Integration/Continuous Deployment (CI/CD): GitLab
              offers robust CI/CD capabilities, allowing teams to automate the
              build, test, and deployment processes. It integrates with various
              tools and supports pipelines, where developers can define stages
              and jobs to execute their code.
            </li>
            <li>
              Code Review: GitLab facilitates code collaboration and review
              through merge requests. Developers can propose changes to the
              codebase, and other team members can review, comment, and suggest
              modifications before merging them into the main branch.
            </li>
            <li>
              Wiki and Documentation: GitLab provides a wiki feature for
              creating and maintaining documentation related to projects. It
              allows teams to document processes, guidelines, and
              project-specific information.
            </li>
            <li>
              Integration and Extensibility: GitLab integrates with a wide range
              of third-party tools and services, such as issue trackers, project
              management systems, and notification systems. It also offers a
              comprehensive API and webhook support for custom integrations.
            </li>
            <li>
              Access Control and Permissions: GitLab provides granular access
              control and permissions, allowing organizations to define roles
              and restrictions for different team members. This ensures that the
              right people have appropriate access to the codebase and project
              resources.
            </li>
          </ol>
          <p>
            GitLab offers various pricing tiers, including free and paid plans,
            depending on the features and support level required. The commercial
            use of GitLab allows organizations to leverage its comprehensive
            suite of development tools to streamline their software development
            processes, improve collaboration, and achieve efficient CI/CD
            workflows.
          </p>
        </div>
      </ProductItem>
      {/* <ProductItem
        header="SonarQube"
        image="/images/product/product5.png"
        maxwid="700px"
        id="SonarQube"
      ></ProductItem>
      <ProductItem
        header="Tencent Cloud"
        image="/images/product/product6.png"
        maxwid="700px"
        id="TencentCloud"
        reverse
      ></ProductItem> */}
      <ProductItem
        header="Kong"
        image="/images/product/product7.png"
        maxwid="700px"
        id="Kong"
        ref1={KongRef}
      >
        <div>
          <p>
            Kong API Gateway is an open-source API gateway and microservices
            management layer that can be used for commercial purposes. It
            provides a centralized platform to manage, secure, and control APIs
            and microservices.
          </p>
          <p>
            Kong offers a range of features and functionalities that make it
            suitable for commercial use :
          </p>
          <ol style={{ marginInlineStart: "20px" }}>
            <li>
              API Gateway: Kong acts as a gateway for your APIs, enabling you to
              manage traffic, handle authentication and authorization, and
              enforce security policies.
            </li>
            <li>
              Request and Response Transformations: Kong allows you to modify
              and transform API requests and responses, enabling you to
              manipulate data, add or remove headers, and perform various
              transformations.
            </li>
            <li>
              Load Balancing: Kong supports load balancing across multiple
              backend services, helping you distribute traffic efficiently and
              ensure high availability.
            </li>
            <li>
              Authentication and Authorization: Kong provides various
              authentication and authorization mechanisms, including key
              authentication, JWT, OAuth, and more. This allows you to control
              access to your APIs and ensure only authorized clients can consume
              them.
            </li>
            <li>
              Rate Limiting and Quotas: Kong allows you to set rate limits and
              quotas on API usage, helping you manage and control traffic to
              prevent abuse and ensure fair usage.
            </li>
            <li>
              Logging and Monitoring: Kong offers logging and monitoring
              capabilities, allowing you to track and analyze API usage, monitor
              performance, and gain insights into your system.
            </li>
            <li>
              Plugin System: Kong has a plugin system that extends its
              functionality. You can leverage existing plugins or develop custom
              plugins to add additional features and integrate with other
              services.
            </li>
          </ol>
          <p>
            While Kong is open-source and freely available, there is also a
            commercial version called Kong Enterprise. Kong Enterprise provides
            additional features, enterprise-grade support, and additional
            scalability options for organizations with more demanding
            requirements.
          </p>
          <p>
            To use Kong commercially, you can choose either the open-source
            version or opt for Kong Enterprise, depending on your specific needs
            and requirements.
          </p>
        </div>
      </ProductItem>
      <ProductItem
        header="ELK"
        image="/images/product/elk.png"
        maxwid="700px"
        id="ELK"
        ref1={ELKRef}
        reverse
      >
        <div>
          <p>
            ELK is an acronym that refers to a stack of three open-source
            software products: Elasticsearch, Logstash, and Kibana. Together,
            they form a powerful and versatile platform for log management and
            analytics. Here's an overview of each component and its features :
          </p>
          <ol style={{ marginInlineStart: "20px" }}>
            <li>
              Elasticsearch: It is a distributed, real-time search and analytics
              engine. Key features include :
            </li>
            <ul style={{ marginInlineStart: "20px" }}>
              <li>
                Full-text search: Elasticsearch enables fast and accurate
                full-text search across large volumes of data.
              </li>
              <li>
                Distributed architecture: It is designed to scale horizontally,
                allowing you to store and process massive amounts of data across
                multiple nodes.
              </li>
              <li>
                Near real-time data processing: Elasticsearch provides near
                real-time indexing and search capabilities, allowing you to
                analyze data as it arrives.
              </li>
              <li>
                Document-oriented: Data is stored in the form of JSON documents,
                providing flexibility in structuring and querying the data.
              </li>
              <li>
                Advanced search functionalities: Elasticsearch supports complex
                search queries, aggregations, filtering, and geospatial search.
              </li>
              <li>
                High availability and fault tolerance: It offers built-in
                replication and sharding mechanisms to ensure data availability
                and fault tolerance.
              </li>
            </ul>
            <li>
              Logstash: It is a data collection and ingestion pipeline tool. Its
              features include :
            </li>
            <ul style={{ marginInlineStart: "20px" }}>
              <li>
                Data ingestion: Logstash allows you to collect data from various
                sources such as log files, databases, message queues, and more.
              </li>
              <li>
                Data transformation: It provides a wide range of filters and
                plugins to parse, enrich, and transform data before sending it
                to Elasticsearch or other outputs.
              </li>
              <li>
                Extensibility: Logstash is highly extensible, allowing you to
                create custom plugins to cater to specific data processing
                needs.
              </li>
              <li>
                Scalability: It can handle high-volume data streams and can be
                easily scaled horizontally to accommodate increased data loads.
              </li>
            </ul>
            <li>
              Kibana: It is a web-based data visualization and exploration tool.
              Some of its features include :
            </li>
            <ul style={{ marginInlineStart: "20px" }}>
              <li>
                Data visualization: Kibana offers a rich set of visualizations
                such as line charts, bar charts, maps, and histograms to
                represent data in a meaningful way.
              </li>
              <li>
                Dashboard creation: It allows you to create interactive
                dashboards by combining multiple visualizations and adding
                filters and controls.
              </li>
              <li>
                Ad hoc querying: Kibana provides an intuitive interface for
                constructing ad hoc queries and exploring data interactively.
              </li>
              <li>
                Centralized log management: You can use Kibana to search,
                filter, and analyze logs stored in Elasticsearch, enabling
                centralized log management and troubleshooting.
              </li>
              <li>
                User management and access control: Kibana supports user
                authentication and authorization, allowing you to control access
                to data and dashboards.
              </li>
            </ul>
          </ol>
          <p>
            The ELK stack is widely used for log analysis, monitoring, and
            real-time analytics across various industries and use cases. It
            provides a powerful set of tools for collecting, processing,
            storing, and visualizing data.
          </p>
        </div>
      </ProductItem>
    </div>
  );
};

export default Products;
