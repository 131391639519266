import React from "react";
import CompLink from "./CompLink";
const TemplateLink = () => {
  return (
    <div>
      <div>
        <CompLink
          image="/images/template1/template1.jpg"
          detail="เส้นทางสู่การเป็น DevOps เริ่มจาก 0 ตอนแรก"
          href="/Template1"
        />
        <CompLink
          image="/images/template2/template2.jpg"
          detail="DevSecOps and DevOps as Professional Services"
          href="/Template2"
        />
        <CompLink
          image="/images/template3/template3.jpg"
          detail="DevOps as a Professional Service"
          href="/Template3"
        />
        <CompLink
          image="/images/template4/template4.jpg"
          detail="DevSecOps Integration with DevOps as a Service"
          href="/Template4"
        />
        <CompLink
          image="/images/template5/template5.jpg"
          detail="Empower Your Business with ELK"
          href="/Template5"
        />
        <CompLink
          image="/images/template6/template6.jpg"
          detail="Professional DevOps and DevSecOps Services"
          href="/Template6"
        />
      </div>
    </div>
  );
};
export default TemplateLink;
