import React, { useEffect } from "react";
import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  addUserMessage,
} from "react-chat-widget-2";

import "react-chat-widget-2/lib/styles.css";

function ChatWidget() {
  useEffect(() => {
    addResponseMessage("How can i help you?");
  }, []);

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };

  return (
    <Widget
      handleNewUserMessage={handleNewUserMessage}
      title="How can i help you?"
      subtitle="And my cool subtitle"
      profileAvatar="images/all/arctica.png"
    />
  );
}

export default ChatWidget;
