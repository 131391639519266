import React from "react";

const EventComp = ({ header, image, id, date, detail, tag }) => {
  return (
    <div
      style={{
        paddingInline: "100px",
        paddingBlock: "30px",
        backgroundColor: "white",
      }}
    >
      <div className="text-center position-relative">
        <img
          src={image}
          id={id}
          alt={id}
          style={{
            width: "100%",
            // maxWidth: "600px",
            textAlign: "center",
            maxHeight: "400px",
            objectFit: "cover",
          }}
        />
        <div className="position-absolute top-100 start-50 translate-middle">
          <div className="w-100">
            <p
              className="m-0 p-2 px-3"
              style={{
                backgroundColor: "#e4e4e4",
                borderRadius: "25px",
                fontSize: "14px",
              }}
            >
              {date}
            </p>
          </div>
        </div>
      </div>
      <div className="py-3">
        <br></br>
        <h1 style={{ fontSize: "24px", fontWeight: "bold" }}>{header}</h1>
        <p style={{ textIndent: "3%" }}>{detail}</p>
        {/* <br></br>
        <p>TAG : {tag}</p> */}
      </div>
    </div>
  );
};
export default EventComp;
