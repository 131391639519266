import React from "react";

const Footer = () => {
  return (
    <div className="shadow-lg bgfooter">
      <div className="row pt-3 text-light" style={{ margin: "auto" }}>
        <div className="col-lg-5 pb-3">
          <img
            src="/images/LOGO.png"
            alt="logo"
            style={{ width: "100%", marginLeft: "70px", maxWidth: "350px" }}
          />
          <p
            className=" text-light"
            style={{
              margin: "45px",
              marginInline: "20%",
            }}
          >
            ARCTICA, a leading DevOps/DevSecOps professional solutions company
            based in Thailand. We are committed to delivering high-quality,
            reliable solutions that meet the highest industry standards.
          </p>
          <div className="d-flex justify-content-center">
            <a href="https://www.facebook.com/arcticathailand" target="_blank">
              <img
                src="/images/social/fb.png"
                width="30px"
                className="me-5"
                alt="facebook link"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/arctica-thailand/"
              target="_blank"
            >
              <img
                src="/images/social/lk.png"
                width="30px"
                className="me-5"
                alt="Linkined link"
              />
            </a>
            <a href="#" target="_blank">
              <img
                src="/images/social/yt.png"
                width="30px"
                className="me-5"
                alt="Youtube link"
              />
            </a>
            <a
              href="https://jobs.blognone.com/company/arctica-co-ltd"
              target="_blank"
            >
              <img
                src="/images/social/bn.png"
                width="30px"
                className="me-5"
                alt="Blognone link"
              />
            </a>
            <a href="https://lin.ee/hebv3OH" target="_blank">
              <img
                src="/images/social/ln.png"
                width="30px"
                className="me-5"
                alt="Blognone link"
              />
            </a>
          </div>
        </div>
        <div className="col-lg-7 row">
          <div
            className="col-4"
            style={{ marginInline: "auto" }}
            id="list-solution"
          >
            <p>Solution</p>

            <ul>
              <li>
                <a href="/DevOps" className="footerLink text-light">
                  DevOps Solution
                </a>
              </li>
              <li>
                <a href="/DevSecOps" className="footerLink text-light">
                  DevSecOps Solution
                </a>
              </li>
              <li>
                <a href="/CloudNative" className="footerLink text-light">
                  Cloud-Native
                </a>
              </li>
              <li>
                <a href="/Consulting" className="footerLink text-light">
                  Consulting
                </a>
              </li>
              <li>
                <a href="/PerformanceTest" className="footerLink text-light">
                  Performance Test
                </a>
              </li>
              <li>
                <a href="/AutomatedTest" className="footerLink text-light">
                  Automated Test
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4 " style={{ marginInline: "auto" }}>
            <p>Products</p>
            <ul>
              <li>
                <a href="/Checkmarx" className="footerLink text-light">
                  Checkmarx
                </a>
              </li>
              <li>
                <a href="/Huawei" className="footerLink text-light">
                  Huawei
                </a>
              </li>
              <li>
                <a href="/Gitlab" className="footerLink text-light">
                  GitLab
                </a>
              </li>

              <li>
                <a href="/Kong" className="footerLink text-light">
                  Kong
                </a>
              </li>
              <li>
                <a href="/ELK" className="footerLink text-light">
                  ELK
                </a>
              </li>
            </ul>
          </div>
          <div className="col-4" style={{ marginInline: "auto" }}>
            <p>About</p>
            <ul>
              <li>
                <a href="/#tools" className="footerLink text-light">
                  Our Technology
                </a>
              </li>
              <li>
                <a href="/#tools" className="footerLink text-light">
                  Our Certified
                </a>
              </li>
              <li>
                <a href="/Contactus" className="footerLink text-light">
                  About Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-2"></div>
      <div
        className="text-center pb-3"
        style={{ color: "gray", fontSize: "14px" }}
      >
        50/62 Moo.5, Lat Sawai, Lam Luk Ka, Pathum Thani 12150 Thailand Tel:
        +662-1620737 Mobile: +6698 3679224 Email: arctica_ae@arctica.co.th
      </div>
    </div>
  );
};

export default Footer;
