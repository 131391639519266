import React from "react";

const HomeIntro = ({ header, detail }) => {
  return (
    <div>
      <div className="row text-white" style={{ backgroundColor: "#242725" }}>
        <div
          className="col-lg-6 d-flex align-items-center flex-end"
          style={{ justifyContent: "center" }}
        >
          <div
            className="text-end reveal fade-left text-res-center"
            style={{ fontWeight: "bold" }}
          >
            <p style={{ fontSize: "18px" }}>{header}</p>
          </div>
        </div>
        <div
          className="col-lg-6 d-flex align-items-center flex-start"
          style={{ justifyContent: "center" }}
        >
          <div className="reveal fade-right">
            <p style={{ fontSize: "16px", color: "white" }}>{detail}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIntro;
