import React from "react";

const ProductItem = ({
  header,
  detail,
  image,
  reverse,
  maxwid,
  ref1,
  id,
  children,
}) => {
  return (
    <div className="bg-white border border-5">
      <div
        className={`row py-3 ${
          reverse
            ? "d-flex flex-row-reverse align-items-center "
            : "d-flex flex-row align-items-center"
        } `}
        style={{
          width: "100%",
          maxWidth: "1700px",
          margin: "auto",
          paddingInline: "100px",
        }}
      >
        <div
          className={` ${
            reverse ? "col-lg-3 text-center" : "col-lg-3 text-center"
          } `}
        >
          <img
            src={image}
            style={{
              width: "100%",
              maxWidth: maxwid,
              background: "white",
              padding: "10%",
              texShadow: "20px 20px 50px red",
            }}
          />
        </div>
        <div
          className={`${
            reverse ? "col-lg-9 text-end" : "col-lg-9 text-start"
          } `}
          id={id}
          ref={ref1}
          style={{ fontSize: "6px" }}
        >
          <h1 className="text-center">{header}</h1>
          <p className="text-start">{children || detail}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
