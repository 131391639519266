import React from "react";
const TechComp = ({ image, detail, href }) => {
  return (
    <div className="text-center mx-2 mt-3 mb-4">
      <div
        className="py-3"
        style={{
          width: "100%",
          backgroundColor: "white",
          margin: "auto",
        }}
      >
        <a href={href} className="footerLink">
          <img
            src={image}
            alt={detail}
            style={{
              height: "180px",
              objectFit: "contain",
              maxWidth: "260px",
              width: "100%",
              paddingInline: "20px",
            }}
          />
        </a>
        <p className="m-0 py-2" style={{ fontSize: "16px" }}>
          {detail}
        </p>
      </div>
    </div>
  );
};
export default TechComp;
