import React from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../component/Footer";
import { TextField } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ChatWidget from "../component/ChatWidget";
import InstantSearch from "../component/InstantSearch";
import Autocomplete from "@mui/material/Autocomplete";
import SearchList from "../json/SearchList";
const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const styles = {
    textMenu: {
      fontSize: "16px",
      color: "#032a77",
      paddingInline: "20px",
      fontWeight: "bold",
    },
  };

  const menuList = [
    {
      label: "HOME",
      path: "/",
      children: [],
    },
    {
      label: "SOLUTION",
      path: "/Solution",
      children: [
        { label: "DevOps Solution", path: "/DevOps" },
        { label: "DevSecOps Solution", path: "/DevSecOps" },
        { label: "Cloud-Native", path: "/CloudNative" },
        { label: "Automated Test", path: "/AutomatedTest" },
        { label: "Performance Test", path: "/PerformanceTest" },
        { label: "Consulting", path: "/Consulting" },
      ],
    },
    {
      label: "TECHNOLOGY",
      path: "/Products",
      children: [
        { label: "CheckMarkx", path: "/Checkmarx" },
        { label: "Huawei", path: "/Huawei" },
        { label: "GitLab", path: "/Gitlab" },
        { label: "Kong", path: "/Kong" },
        { label: "ELK", path: "/ELK" },
      ],
    },
    {
      label: "ARTICLE",
      path: "/",
      children: [
        {
          label: "เส้นทางสู่การเป็น DevOps เริ่มจาก 0 ตอนแรก",
          path: "/Template1",
        },
        {
          label: "DevSecOps and DevOps as Professional Services",
          path: "/Template2",
        },
        { label: "DevOps as a Professional Service", path: "/Template3" },
        {
          label: "DevSecOps Integration with DevOps as a Service",
          path: "/Template4",
        },
        {
          label: "Empower Your Business with ELK",
          path: "/Template5",
        },
        {
          label: "Professional DevOps and DevSecOps Services",
          path: "/Template6",
        },
      ],
    },
    {
      label: "EVENTS",
      path: "/Event",
      children: [],
    },
    {
      label: "CAREERS",
      path: "/Careers",
      children: [],
    },
    {
      label: "ABOUT US",
      path: "/Contactus",
      children: [],
    },
  ];

  const displayTopBar = (
    <nav className="navbar navbar-expand-lg shadow bgMenu py-0">
      <div className="container-fluid" style={{ paddingInline: "10px 10px" }}>
        <div className="navbar-brand">
          <div
            className="position-relative"
            onClick={() => navigate("/")}
            role="button"
          >
            <img
              src="images/LOGO.png"
              style={{
                width: "100%",
                maxWidth: "250px",
                marginLeft: "20px",
              }}
            />
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse justify-content-center"
          id="navbarNav"
        >
          <ul className="navbar-nav">
            {menuList?.map((item) => {
              return item.children.length ? (
                <li className="nav-item dropdown btnMenu">
                  <a
                    className={`nav-link dropdown-toggle menu-item`}
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={styles.textMenu}
                  >
                    {item.label}
                  </a>
                  <ul className="dropdown-menu">
                    {item.children.map((subItem) => {
                      return (
                        <li
                          className={`menu-item btnSubMenu ${
                            pathname === subItem.path ? "menu-active" : ""
                          }`}
                        >
                          <Link
                            className="dropdown-item py-2"
                            to={subItem.path}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            {subItem.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li
                  className={`nav-item menu-item btnMenu ${
                    pathname === item.path ? "menu-active" : ""
                  }`}
                >
                  <Link
                    className="nav-link"
                    to={item.path}
                    style={styles.textMenu}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <div>
            {/* <InstantSearch /> */}
            {/* <TextField
              label="Search"
              InputProps={{
                endAdornment: <SearchRoundedIcon />,
              }}
              sx={{
                "& fieldset": {
                  borderRadius: "30px",
                  marginBlock: "8px",
                },
              }}
            /> */}

            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                console.log("change ", newValue);

                setValue(newValue);
                if (newValue) {
                  navigate(newValue.page);
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={SearchList}
              sx={{ width: "250px", marginInlineEnd: "15px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  // InputProps={{
                  //   endAdornment: <SearchRoundedIcon />,
                  // }}
                  sx={{
                    "& fieldset": {
                      borderRadius: "30px",
                      marginBlock: "8px",
                      marginInlineEnd: "15px",
                      width: "250px",
                    },

                    "& input": { fontSize: "12px" },
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </nav>
  );

  return (
    <div>
      {displayTopBar}

      {/* <ChatWidget /> */}
      <div className="bg">
        <Outlet />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
