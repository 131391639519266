import React from "react";

const AutoSlide2 = ({ image, detail, href }) => {
  return (
    <div className="text-center mx-2 mt-5 mb-1">
      <div
        style={{
          border: "5px solid #67C1D6",
          maxWidth: "280px",
          width: "100%",
          backgroundColor: "white",
          margin: "auto",
        }}
      >
        <a href={href} className="footerLink">
          <img
            src={image}
            alt={detail}
            style={{
              height: "220px",
              objectFit: "cover",
              maxWidth: "350px",
              width: "100%",
            }}
          />
        </a>
        <p
          className="m-0 py-2"
          style={{ fontSize: "18px", fontWeight: "bold" }}
        >
          {detail}
        </p>
      </div>
    </div>
  );
};

export default AutoSlide2;
