import React from "react";
// import CompLink from "../component/CompLink";
// import EventComp from "../component/EventComp";
// import Events from "../json/Event";
import TemplateLink from "../component/TemplateLink";

const Template6 = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template2/template2.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row"
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="pt-5">
              <div
                style={{
                  background: "white",
                  padding: "4rem",
                }}
              >
                <h1>
                  <b>Title: Professional DevOps and DevSecOps Services</b>
                </h1>
                <br></br>
                <p>
                  Our company provides professional DevOps and DevSecOps
                  services to help organizations improve their software delivery
                  and quality while maintaining security. We specialize in
                  delivering these services as a managed service, providing
                  organizations with access to DevOps and security expertise
                  without having to build and maintain their own infrastructure.
                </p>
                <p>
                  Our DevOps services include continuous integration and
                  delivery, automation, monitoring, and more. We work with our
                  clients to tailor our services to their unique needs, whether
                  they're a small startup or a large enterprise. Our goal is to
                  help our clients deliver products faster and more efficiently,
                  while improving quality and reducing costs.
                </p>
                <p>
                  Our DevSecOps services take DevOps to the next level by
                  integrating security practices into every stage of the
                  development process. We help our clients automate security
                  testing, vulnerability scanning, compliance monitoring, risk
                  management, and more. By integrating security practices into
                  DevOps, we help our clients achieve greater efficiency, speed,
                  and security.
                </p>
                <p>
                  At our company, we pride ourselves on delivering high-quality
                  services that are tailored to our clients' needs. We have a
                  team of experienced DevOps and security professionals who are
                  dedicated to helping our clients achieve their goals. Whether
                  you're looking to improve your software delivery, increase
                  security, or reduce costs, we're here to help.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <TemplateLink />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template6;
