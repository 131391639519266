import React from "react";
// import ImageSlider from "../component/ImageSlider";
import Carousel from "react-multi-carousel";
import AutoSlide from "../component/autoslide";
import HomeIntro from "../component/homeIntro";
import AutoSlide2 from "../component/autoslide2";
// import { Link } from "react-router-dom";
import "animate.css";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Home = () => {
  const { hash } = useLocation();

  const introduceRef = useRef(null);
  const whychooseRef = useRef(null);
  const articlesRef = useRef(null);
  const serviceRef = useRef(null);
  const toolsRef = useRef(null);
  const supportRef = useRef(null);

  useEffect(() => {
    if (hash) {
      const allHash = [
        { id: "introduce", ref: introduceRef },
        { id: "whychoose", ref: whychooseRef },
        { id: "articles", ref: articlesRef },
        { id: "service", ref: serviceRef },
        { id: "tools", ref: toolsRef },
        { id: "support", ref: supportRef },
      ];

      const focusHash = allHash.find(
        (item) => item.id === hash.replace("#", "")
      );

      setTimeout(() => {
        if (focusHash) {
          focusHash.ref.current.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        }
      }, 1000);
    }
  }, [hash]);

  // const images1 = [
  //   { image: "/images/home/slide1.png", text: "Tools & Texhnologies we use" },
  //   { image: "/images/home/slide2.png", text: "Our Certified" },
  // ];

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          color: "white",
          top: "50px",
          width: "100%",
          // right: 0,
        }}
      >
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <h1
              className="fn-nexa"
              style={{ color: "white", fontWeight: "800", margin: "auto" }}
            >
              ARCTICA SERVICE OFFERING
            </h1>
          </div>
          <div>
            <IconButton
              onClick={previous}
              sx={{ background: "white", border: " 1px solid black", m: 1 }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={next}
              sx={{ border: " 1px solid white", m: 1 }}
            >
              <ChevronRight sx={{ color: "white" }} />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="position-relative">
        <img
          src="images/home/cover.jpg"
          alt="Home"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
          className="brightness"
        />
        <div
          className="position-absolute d-flex align-items-center"
          style={{
            backgroundColor: "rgba(156, 224, 233, 0.70)",
            width: "50%",
            top: "0",
            height: "100%",
          }}
        >
          <div className="text-center text-white" style={{ margin: "auto" }}>
            <h1 className="fn-nexa animate__fadeInUp animate__animated">
              <b>DevSecOps</b>
            </h1>
            <h1 className="fn-nexa animate__fadeInUp animate__animated">
              <b>Code faster, secure smarter.</b>
            </h1>
            <br></br>
            <p className="fn-anonymous animate__animated ">
            Welcome to ARCTICA DevSecOps as a Professional Service, 
              <br></br>your trusted partner in secure DevOps.
            </p>
            <a href="Contactus">
              <button
                style={{
                  padding: "10px 35px 10px 35px",
                  border: "3px solid black",
                }}
              >
                <b>CONTACT US</b>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div
          className="row text-white pt-3 text-center"
          ref={introduceRef}
          style={{ backgroundColor: "#242725 ", paddingInline: "50px" }}
        >
          <div className="col-sm-1 px-0"></div>
          <div className="col-sm-2 px-0">
            <a href="#whychoose" className="home-link">
              WHY CHOOSE ARCTICA
            </a>
          </div>
          <div className="col-sm-2 px-0">
            <a href="#articles" className="home-link">
              ARTICLES
            </a>
          </div>
          <div className="col-sm-2 px-0">
            <a href="#service" className="home-link">
              SERVICE
            </a>
          </div>
          <div className="col-sm-2 px-0">
            <a href="#tools" className="home-link">
              TOOLS&TECHNOLOGY
            </a>
          </div>
          <div className="col-sm-2 px-0">
            <a href="#support" className="home-link">
              SUPPORT
            </a>
          </div>
          <div className="col-sm-1 px-0"></div>
          <div
            className="home-border my-3"
            style={{ height: "1px", width: "35%", margin: "auto" }}
          ></div>
        </div>

        <HomeIntro
          header="DevSecOps and DevOps are powerful"
          detail="Weleome to ARCTICA DevSecOps"
        />
        <HomeIntro
          header="methodologies that can help organizations of all"
          detail="Your professional service for all things Kubernetes, Docker, and"
        />
        <HomeIntro
          header="sizes achieve faster software delivery, better"
          detail="automate testing. Our expertise extends to consulting. code"
        />
        <HomeIntro
          header="Collaboration between tesms, and improved"
          detail="scanning. monitoring and logging, as well as other
                DevOps-related"
        />
        <HomeIntro header="product quality" detail="services" />
        <div className="pb-5 pt-3" style={{ backgroundColor: "#242725" }}>
          <div
            className="home-border mb-3"
            style={{
              height: "1px",
              width: "25%",
              margin: "auto",
            }}
          ></div>
        </div>
      </div>

      <div className="py-5">
        <div className="position-relative" ref={whychooseRef}>
          <div
            className="top-0 start-50 translate-middle-x home-res-center-text"
            style={{
              position: "relative",
              textAlign: "left",
              width: "100%",
              maxWidth: "250px",
            }}
          >
            <p
              style={{
                fontSize: "32px",
              }}
              className="home-shadowFont my-0 fn-nexa reveal fade-right"
            >
              WHY CHOOSE
            </p>
            <p
              style={{
                fontSize: "42px",
              }}
              className="home-shadowFont fn-nexa reveal fade-right"
            >
              ARCTICA?
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 textend" style={{ textAlign: "center" }}>
              <img
                src="images/home/whychoose.png"
                alt="Why Choose Acrtica?"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  marginInline: "60px",
                }}
                className="home-pic"
              />
            </div>
            <div
              className="col-lg-6 d-flex align-items-center flex-start2"
              style={{ justifyContent: "center" }}
            >
              <div
                style={{
                  borderLeft: "3px solid black",
                  paddingInlineStart: "10px",
                }}
              >
                <ul
                  className="home-link2--container"
                  style={{ fontWeight: "bold" }}
                >
                  <li>
                    <a href="/" className="home-link2">
                      Certified DevSecOps Professional
                    </a>
                  </li>
                  <li>
                    <a href="/" className="home-link2">
                      {" "}
                      Continuous Security Testing
                    </a>
                  </li>
                  <li>
                    <a href="/" className="home-link2">
                      Production Solving Skills
                    </a>
                  </li>
                  <li>
                    <a href="/" className="home-link2">
                      Agile Project Management
                    </a>
                  </li>
                  <li>
                    <a href="/" className="home-link2">
                      Tool Customizable upon company knowledge base
                    </a>
                  </li>
                </ul>
                {/* <a
                  className="ms-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    padding: "5px 25px 5px 25px",
                    textDecoration: "none",
                    color: "red",
                  }}
                >
                  READ MORE...
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="position-relative" ref={articlesRef}>
          <div
            className="top-0 start-50 translate-middle-x home-res-center-text"
            style={{ position: "relative", textAlign: "right" }}
          >
            <p
              style={{
                fontSize: "42px",
                textAlign: "center",
              }}
              className="home-shadowFont my-0 fn-nexa reveal fade-left textend"
            >
              ARTICLE
            </p>
            <p
              style={{
                fontSize: "32px",
                textAlign: "center",
              }}
              className="home-shadowFont fn-nexa reveal fade-left textend"
            >
              UPDATE
            </p>
          </div>

          <div className="row d-flex justify-content-center d-flex align-items-center">
            <div className="col-3"></div>
            <div className="col-lg-3 ">
              <div>
                <div
                  style={{
                    borderRight: "3px solid black",
                    paddingInlineEnd: "20px",
                  }}
                  className="d-flex justify-content-end"
                >
                  <ul
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    className="home-link2--container"
                  >
                    <li>
                      <a href="/" className="home-link2">
                        DevSecOps and DevOps as Professional Services: What You
                        Need to Know
                      </a>
                    </li>
                    <li>
                      <a href="/" className="home-link2">
                        DevSecOps Integration with DevOps as a Service
                      </a>
                    </li>
                    <li>
                      <a href="/" className="home-link2">
                        Professional DevOps and DevSecOps Services
                      </a>
                    </li>
                    <li>
                      <a href="/" className="home-link2">
                        Empower Your Business with ELK: The Ultimate Log
                        Management and Analytics Solution
                      </a>
                    </li>
                  </ul>
                  <br></br>
                </div>
                {/* <div
                  style={{
                    borderRight: "3px solid black",
                    paddingInlineEnd: "30px",
                  }}
                >
                  <div className="text-end">
                    <a
                      className="ms-3"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        padding: "5px 25px 5px 25px",
                        textDecoration: "none",
                        color: "red",
                      }}  
                    >
                      READ MORE...
                    </a>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 textstart" style={{ textAlign: "center" }}>
              <img
                src="images/home/update.jpg"
                alt="Why Choose Acrtica?"
                style={{
                  width: "100%",
                  maxWidth: "350px",
                  marginInline: "50px",
                }}
                className="home-pic"
              />
            </div>
          </div>
        </div>
      </div>

      <div ref={serviceRef}>
        <div
          style={{
            backgroundColor: "#242725",
            paddingInline: "150px",
          }}
        >
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            centerMode={false}
            className="carousel-asseenon pb-5 slider"
            containerClass="carousel-asseenon-container"
            customButtonGroup={<CustomButtonGroupAsArrows />}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass="carousel-asseenon-item"
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 4,
              },
            }}
            // sliderClass=""
            // slidesToSlide={3}
            // swipeable
          >
            <div>
              <AutoSlide2
                image="/images/solution/solution1.jpg"
                detail="DevOps Solution"
                href="DevOps"
              />
            </div>
            <div>
              <AutoSlide2
                image="/images/solution/solution2.jpg"
                detail="DevSecOps Solution"
                href="DevSecOps"
              />
            </div>
            <div>
              <AutoSlide2
                image="/images/solution/solution3.jpg"
                detail="Cloud-Native"
                href="CloudNative"
              />
            </div>
            <div>
              <AutoSlide2
                image="/images/solution/solution6.jpg"
                detail="Consulting"
                href="Consulting"
              />
            </div>
            <div>
              <AutoSlide2
                image="/images/solution/solution5.jpg"
                detail="Performance Test"
                href="PerformanceTest"
              />
            </div>
            <div>
              <AutoSlide2
                image="/images/solution/solution4.jpg"
                detail="Automated Test"
                href="AutomatedTest"
              />
            </div>
          </Carousel>
        </div>
        <div style={{ border: "5px solid #67C1D6" }}></div>
      </div>
      <div ref={toolsRef}>
        <div
          style={{
            width: "100%",
            margin: "auto",
          }}
        >
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={3000}
            centerMode={false}
            className="carousel-asseenon slider py-0"
            containerClass="carousel-asseenon-container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass="carousel-asseenon-item"
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
              },
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            <div>
              <div>
                <h1
                  className="text-center pt-5 fn-nexa pb-5"
                  style={{
                    color: "black",
                    fontWeight: "800",
                    background: "#F2F2F2",
                    margin: "auto",
                  }}
                >
                  Tools & Technologies we use
                </h1>
              </div>
              <AutoSlide
                image="/images/logo Rev2/slide2/slide1.png"
                wid="w-75"
              />
            </div>
            <div>
              <div>
                <h1
                  className="text-center pt-5 fn-nexa pb-2"
                  style={{
                    color: "black",
                    fontWeight: "800",
                    background: "#F2F2F2",
                    margin: "auto",
                  }}
                >
                  Our Certified
                </h1>
              </div>
              <AutoSlide
                image="/images/logo Rev2/slide2/slide2.png"
                wid="w-50"
              />
            </div>
          </Carousel>
        </div>
      </div>
      <div
        className="home-border2 my-5 "
        style={{ height: "2px", width: "45%", margin: "auto" }}
      />
      <div className="container py-4">
        <div>
          <h1
            className="fn-nexa"
            ref={supportRef}
            style={{
              fontWeight: "800",
              marginBlockEnd: "30px",
              marginInlineStart: "70px",
            }}
          >
            SUPPORT
          </h1>
        </div>
        <div
          className="d-flex justify-content-center flex-wrap mb-5"
          style={{ gap: "50px" }}
        >
          <CardEl
            image="/images/home/home1.jpg"
            clas="text-center pt-3 bg-white reveal slide-bck-center gelatine reveal"
          >
            <div>Advice Support</div>
          </CardEl>
          <CardEl
            image="/images/home/home2.jpg"
            clas="text-center pt-3 bg-white reveal slide-bck-center gelatine reveal"
          >
            <div>Support Channels</div>
            <div>EMAIL, LINE, PHONE</div>
          </CardEl>
          <CardEl
            image="/images/home/home3.jpg"
            clas="text-center pt-3 bg-white reveal slide-bck-center gelatine reveal"
          >
            <div>Onsite Support</div>
          </CardEl>
          <CardEl
            image="/images/home/home4.jpg"
            clas="text-center pt-3 bg-white reveal slide-bck-center gelatine reveal"
          >
            <div>Online Support</div>
          </CardEl>
          <CardEl
            image="/images/home/home5.jpg"
            clas="text-center pt-3 bg-white reveal slide-bck-center gelatine reveal"
          >
            <div>Business Hour</div>
          </CardEl>
        </div>
      </div>
    </div>
  );
};

export default Home;

const CardEl = ({ image, header, clas, children }) => {
  return (
    <div className="reveal">
      <div
        className={clas}
        style={{ border: "4px solid black", width: "290px" }}
      >
        <div>
          <p style={{ fontWeight: "bold", fontSize: "18px", height: "40px" }}>
            {children || header}
          </p>
          <img
            src={image}
            alt={header}
            style={{
              width: "80%",
              height: "130px",
              objectFit: "cover",
              paddingInline: "30px",
            }}
            className="home-pic"
          />
          <div className="text-start ps-5 pt-2">
            <p
              className="d-flex align-items-center mb-1"
              style={{ fontSize: "14px" }}
            >
              <img
                src="/images/home/accept.png"
                alt="accept"
                style={{
                  width: "100%",
                  maxWidth: "15px",
                  marginInlineEnd: "10px",
                }}
              />{" "}
              Arctica {"(8 x 5)"}
            </p>
            <p
              className="d-flex align-items-center py-0"
              style={{ fontSize: "14px" }}
            >
              <img
                src="/images/home/accept.png"
                alt="accept"
                style={{
                  width: "100%",
                  maxWidth: "15px",
                  marginInlineEnd: "10px",
                }}
              />
              Arctica On Call {"(24 x 7)"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
