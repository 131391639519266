import React from "react";

const CompLink2 = ({ image, detail, href }) => {
  return (
    <div className="text-center mx-2 mt-3 mb-4">
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          margin: "auto",
        }}
      >
        <a href="#" onClick={href} className="footerLink">
          <img
            src={image}
            alt={detail}
            style={{
              height: "180px",
              objectFit: "cover",
              maxWidth: "350px",
              width: "100%",
            }}
          />
        </a>
        <p className="m-0 py-2" style={{ fontSize: "16px" }}>
          {detail}
        </p>
      </div>
    </div>
  );
};

export default CompLink2;
