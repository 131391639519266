import React from "react";
import TechLink from "../component/TechLink";
const Gitlab = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row "
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="py-5 bg-white" style={{ paddingInline: "70px" }}>
              <div className="text-center">
                <img
                  src="/images/product/product4.png"
                  style={{ width: "100%", maxWidth: "540px" }}
                  className="mb-5"
                />
              </div>
              <div>
                <p>
                  GitLab is a web-based DevOps platform that provides a complete
                  set of tools for software development, version control,
                  collaboration, and continuous integration/continuous
                  deployment (CI/CD). It is primarily used for managing and
                  tracking source code repositories, but it also offers
                  additional features to support the entire software development
                  lifecycle.
                </p>
                <p>
                  GitLab offers both cloud-based and self-hosted options,
                  allowing organizations to choose the deployment model that
                  best suits their needs. The self-hosted version enables
                  companies to have complete control over their source code and
                  data by hosting GitLab on their own infrastructure.
                </p>
                <p>Here are some key features and capabilities of GitLab :</p>
                <ol style={{ marginInlineStart: "20px" }}>
                  <li>
                    Version Control: GitLab is built on top of Git, a
                    distributed version control system. It provides powerful Git
                    repository management with features like branching, merging,
                    and conflict resolution.
                  </li>
                  <li>
                    Issue Tracking: GitLab includes an issue tracking system
                    that allows teams to create, assign, and track tasks, bugs,
                    and feature requests. It provides a centralized place for
                    collaboration and discussion around issues.
                  </li>
                  <li>
                    Continuous Integration/Continuous Deployment (CI/CD): GitLab
                    offers robust CI/CD capabilities, allowing teams to automate
                    the build, test, and deployment processes. It integrates
                    with various tools and supports pipelines, where developers
                    can define stages and jobs to execute their code.
                  </li>
                  <li>
                    Code Review: GitLab facilitates code collaboration and
                    review through merge requests. Developers can propose
                    changes to the codebase, and other team members can review,
                    comment, and suggest modifications before merging them into
                    the main branch.
                  </li>
                  <li>
                    Wiki and Documentation: GitLab provides a wiki feature for
                    creating and maintaining documentation related to projects.
                    It allows teams to document processes, guidelines, and
                    project-specific information.
                  </li>
                  <li>
                    Integration and Extensibility: GitLab integrates with a wide
                    range of third-party tools and services, such as issue
                    trackers, project management systems, and notification
                    systems. It also offers a comprehensive API and webhook
                    support for custom integrations.
                  </li>
                  <li>
                    Access Control and Permissions: GitLab provides granular
                    access control and permissions, allowing organizations to
                    define roles and restrictions for different team members.
                    This ensures that the right people have appropriate access
                    to the codebase and project resources.
                  </li>
                </ol>
                <p>
                  GitLab offers various pricing tiers, including free and paid
                  plans, depending on the features and support level required.
                  The commercial use of GitLab allows organizations to leverage
                  its comprehensive suite of development tools to streamline
                  their software development processes, improve collaboration,
                  and achieve efficient CI/CD workflows.
                </p>
              </div>
              <img
                  src="/images/product/4005-GoogleDisplay_Ads_r4-partner-alt_C_720x300.png"
                  style={{ width: "100%", maxWidth: "540px" }}
                  className="mb-5"
                  alt="GitLab"
                />
                &nbsp;
                <img
                  src="/images/product/4005-GoogleDisplay_Ads_r4-partner-alt_D_720x300.png"
                  style={{ width: "100%", maxWidth: "540px" }}
                  className="mb-5"
                  alt="GitLab"
                />
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <TechLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gitlab;
