import React from "react";
import SolutionComp from "./SolutionComp";
const SolutionLink = () => {
  return (
    <div>
      <div>
        <SolutionComp
          image="images/solution/solution1.jpg"
          detail="DevOps Solution"
          href="DevOps"
        />
        <SolutionComp
          image="images/solution/solution2.jpg"
          detail="DevSecOps Solution"
          href="DevSecOps"
        />
        <SolutionComp
          image="images/solution/solution3.jpg"
          detail="Cloud-Native"
          href="CloudNative"
        />
        <SolutionComp
          image="images/solution/solution4.jpg"
          detail="Automated Test"
          href="AutomatedTest"
        />
        <SolutionComp
          image="images/solution/solution5.jpg"
          detail="Performance Test"
          href="PerformanceTest"
        />
        <SolutionComp
          image="images/solution/solution6.jpg"
          detail="Consulting"
          href="Consulting"
        />
      </div>
    </div>
  );
};
export default SolutionLink;
