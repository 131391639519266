import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";

var a = Math.floor(Math.random() * 10) + 1;
var b = Math.floor(Math.random() * 10) + 1;
var c = a + b;
const alertBotton = () => {
  alert("Submit");
};
const Contactus = () => {
  const [phone, setPhone] = useState("");
  return (
    <div>
      <h1
        className="text-center py-3"
        style={{
          textAlign: "center",
          fontSize: "46px",
          // textShadow: "5px 5px 10px #75c1c4",
          color: "#325CAD",
        }}
      >
        <b>ABOUT US</b>
      </h1>
      <p
        className="text-center "
        style={{ paddingInline: "25%", color: "black" }}
      >
        Welcome to ARCTICA DevSecOps, your professional service for all things
        Kubernetes, Docker, and automated testing. Our expertise extends to
        consulting, code scanning, monitoring, and logging, as well as other
        DevOps-related services.
        <br></br>
        <br></br>
        We have a proven track record of helping organizations migrate from
        legacy systems to container platforms, and we specialize in building a
        DevOps culture that suits your specific needs. Whether you're looking
        for assistance with loaded testing or simply need some guidance on the
        best practices for DevOps, we're here to help.
      </p>

      <div
        className="row border my-5 bg-white align-items-center "
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "auto",
          padding: "50px",
        }}
      >
        <div className="col-lg-8 pe-5">
          <h1 style={{ fontWeight: "bold" }}>Hello!</h1>
          <p>Get in touch with us today and we'll contact you soon :)</p>
          <div className="row my-2">
            <div className="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Your name"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-lg-6">
              <input
                type="email"
                class="form-control"
                placeholder="Your Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-6">
              <PhoneInput
                country={"eg"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputStyle={{ width: "100%", padding: "6px 12px 6px 50px" }}
              />
            </div>
            <div className="col-lg-6">
              <input
                type="text"
                class="form-control"
                placeholder="Company"
                aria-label="Company"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <div>
            <input
              type="text"
              class="form-control pb-5"
              placeholder="Comments"
              aria-label="Comments"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="form-check my-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" for="flexCheckDefault" />I agree to
            Arctica's Privacy Policy I agree to Arctica's &nbsp;
            <a
              href="https://pdpa.pro/policies/view/th/YiFtJDn96oK1tmfqhBLQcdiC"
              target="_black"
            >
              Privacy Policy*
            </a>
          </div>
          {/* <div className="row">
            <div className="col-lg-8">
              <p>
                Math question {a} + {b} =
              </p>

              <input
                type="number"
                class="form-control"
                placeholder="number"
                aria-label="number"
                aria-describedby="basic-addon1"
              />
              <p style={{ fontSize: "14px", marginBlock: "12px" }}>
                Solve this simple math problem and enter the result. E.g. for
                1+3, enter 4.
              </p>
            </div>
            <div className="col-lg-4"></div>
          </div> */}
          <div className="row">
            <div className="col-lg-6">
              <button
                onClick={alertBotton}
                type="button"
                style={{
                  background: "blue",
                  fontWeight: "bold",
                  color: "white",
                  border: "none",
                  borderRadius: "12px",
                  padding: "3%",
                  paddingInline: "20%",
                }}
              >
                SUBMIT
              </button>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
        <div
          className="col-lg-4 text-center mt-5"
          style={{
            backgroundColor: "#ededed",
            borderRadius: "15px",
            padding: "20px 20px 20px 20px",
            margin: "auto",
          }}
        >
          <p>THAILAND</p>
          <p>Arctica co.,Ltd.</p>
          <p style={{ marginBlockEnd: "2px" }}>
            50/62 Moo.5, Lat Sawai, Lam Luk Ka, Pathum Thani 12150{" "}
          </p>
          <p style={{ marginBlockEnd: "2px" }}>Tel: +662-1620737</p>
          <p style={{ marginBlockEnd: "2px" }}>Mobile: +6698 3679224 </p>
          <p style={{ marginBlockEnd: "2px" }}>
            Email: keerata.sa@arctica.co.th
          </p>
        </div>
      </div>
      <div
        className="row pb-5 d-flex justify-content-center"
        style={{
          width: "100%",
          maxWidth: "1000px",
          margin: "auto",
          paddingInline: "10px",
        }}
      >
        {/* <div className="col-lg-1 text-center"></div> */}
        <div className="col-lg-4 text-center">
          <img
            src="images/home/whychoose.png"
            style={{ width: "100%", margin: "auto", maxWidth: "450px" }}
          />
        </div>
        <div className="col-lg-8 text-black">
          <p style={{ fontWeight: "bold", marginBlockEnd: "3px" }}>THAILAND</p>
          <p>
            Our team is dedicated to providing top-notch service and ensuring
            that your organization is equipped with the tools and expertise
            necessary to succeed. We believe in working closely with our clients
            to understand their unique needs and challenges, and we're committed
            to delivering solutions that are tailored to your specific
            situation.
            <br></br>
            <br></br>
          </p>
          <p>
            If you're ready to take your organization's DevOps culture to the
            next level, get in touch with us today. We're confident that we have
            the skills and experience necessary to help you achieve your goals.
          </p>
        </div>
        {/* <div className="col-lg-1 text-center"></div> */}
      </div>
    </div>
  );
};

export default Contactus;
