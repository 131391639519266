import React from "react";
import TechLink from "../component/TechLink";
const Kong = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row "
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="py-5 bg-white" style={{ paddingInline: "70px" }}>
              <div className="text-center">
                <img
                  src="/images/product/product7.png"
                  style={{ width: "100%", maxWidth: "500px" }}
                  className="mb-5"
                />
              </div>
              <div>
                <p>
                  Kong API Gateway is an open-source API gateway and
                  microservices management layer that can be used for commercial
                  purposes. It provides a centralized platform to manage,
                  secure, and control APIs and microservices.
                </p>
                <p>
                  Kong offers a range of features and functionalities that make
                  it suitable for commercial use :
                </p>
                <ol style={{ marginInlineStart: "20px" }}>
                  <li>
                    API Gateway: Kong acts as a gateway for your APIs, enabling
                    you to manage traffic, handle authentication and
                    authorization, and enforce security policies.
                  </li>
                  <li>
                    Request and Response Transformations: Kong allows you to
                    modify and transform API requests and responses, enabling
                    you to manipulate data, add or remove headers, and perform
                    various transformations.
                  </li>
                  <li>
                    Load Balancing: Kong supports load balancing across multiple
                    backend services, helping you distribute traffic efficiently
                    and ensure high availability.
                  </li>
                  <li>
                    Authentication and Authorization: Kong provides various
                    authentication and authorization mechanisms, including key
                    authentication, JWT, OAuth, and more. This allows you to
                    control access to your APIs and ensure only authorized
                    clients can consume them.
                  </li>
                  <li>
                    Rate Limiting and Quotas: Kong allows you to set rate limits
                    and quotas on API usage, helping you manage and control
                    traffic to prevent abuse and ensure fair usage.
                  </li>
                  <li>
                    Logging and Monitoring: Kong offers logging and monitoring
                    capabilities, allowing you to track and analyze API usage,
                    monitor performance, and gain insights into your system.
                  </li>
                  <li>
                    Plugin System: Kong has a plugin system that extends its
                    functionality. You can leverage existing plugins or develop
                    custom plugins to add additional features and integrate with
                    other services.
                  </li>
                </ol>
                <p>
                  While Kong is open-source and freely available, there is also
                  a commercial version called Kong Enterprise. Kong Enterprise
                  provides additional features, enterprise-grade support, and
                  additional scalability options for organizations with more
                  demanding requirements.
                </p>
                <p>
                  To use Kong commercially, you can choose either the
                  open-source version or opt for Kong Enterprise, depending on
                  your specific needs and requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <TechLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kong;
