import React from "react";
import TechComp from "./TechComp";
const TechLink = () => {
  return (
    <div>
      <div>
        <TechComp
          image="/images/product/product1.png"
          detail="CheckMarx"
          href="CheckMarx"
        />
        <TechComp
          image="/images/product/product2.png"
          detail="Huawei"
          href="Huawei"
        />
        <TechComp
          image="/images/product/product4.png"
          detail="GitLab"
          href="GitLab"
        />
        <TechComp
          image="/images/product/product7.png"
          detail="Kong"
          href="Kong"
        />
        <TechComp image="/images/product/elk.png" detail="ELK" href="ELK" />
      </div>
    </div>
  );
};
export default TechLink;
