const Events = [
  {
    header: "Arctica Meetup’23",
    image: "/images/events/event1.jpg",
    id: "1",
    date: "22 March 2023, at Best Western Nada Don Mueang Airport",
    detail:
      "The first official seminar with our co-venture are Get on Technology , Git Lab ,Checkmarx and Huawei  under the project  Security, Tools and automation for our business client. This project goals is to show alternative solution for manage infrastructure and prevent the risk and vulnerability that may cause damage  in their own enterprise.",
    tags: ["All", "Arctica", "Thailand"],
  },
  {
    header: "DigiTech Asean Thailand 2022 Booth E14",
    image: "/images/events/event2.jpg",
    id: "2",
    date: "23 - 25 Nov 2022, at Hall 7, Impact Exhibition Center,Bangkok, Thailand",
    detail:
      "Our company has given a chance to be present our work at DigiTech Asean Thailand, the most famous exhibition to promote IT and network infrastructure in thailand. Our company has a chance to introduce our work concepts to increase work efficiencies by using alternative tools to support developer and operation.",
    tags: ["All", "DigiTech", "Thailand"],
  },
];

export default Events;
