import React from "react";
import TechLink from "../component/TechLink";
const Huawei = () => {
  return (
    <div>
      <div className="position-relative">
        <img
          src="images/template1/template1.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row "
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "9999" }}>
            <div className="py-5 bg-white" style={{ paddingInline: "70px" }}>
              <div className="text-center">
                <img
                  src="/images/product/product2.png"
                  style={{ width: "100%", maxWidth: "240px" }}
                  className="mb-5"
                />
              </div>
              <div>
                <p>
                  Huawei Cloud is a cloud computing platform offered by Huawei
                  Technologies, a leading global provider of information and
                  communication technology (ICT) infrastructure and smart
                  devices. Huawei Cloud provides a wide range of cloud-based
                  services and solutions to individuals, businesses, and
                  organizations. Here are some key features of Huawei Cloud :
                </p>
                <ol style={{ marginInlineStart: "20px" }}>
                  <li>
                    Computing Services: Huawei Cloud offers scalable and
                    flexible computing resources, including virtual machines
                    (VMs), bare metal servers, and container instances. Users
                    can easily provision and manage computing resources to meet
                    their specific requirements.
                  </li>
                  <li>
                    Storage Services: Huawei Cloud provides various storage
                    options, such as object storage, block storage, and file
                    storage. These services enable users to store and manage
                    their data efficiently and securely.
                  </li>
                  <li>
                    Database Services: Huawei Cloud offers managed database
                    services, including Relational Database Service (RDS) and
                    Distributed Database Service (DDS). These services provide
                    users with highly available and scalable database solutions.
                  </li>
                  <li>
                    Networking Services: Huawei Cloud includes networking
                    services such as Virtual Private Cloud (VPC) and Elastic
                    Load Balance (ELB), which allow users to create isolated
                    virtual networks and distribute network traffic across
                    multiple instances.
                  </li>
                  <li>
                    Security and Compliance: Huawei Cloud incorporates advanced
                    security measures to protect user data and resources. It
                    offers features like identity and access management, network
                    isolation, and encryption to ensure data confidentiality and
                    integrity.
                  </li>
                  <li>
                    AI and Big Data Services: Huawei Cloud provides a range of
                    AI and big data services, including machine learning, data
                    analytics, and data warehousing. These services empower
                    users to extract insights from their data and leverage
                    artificial intelligence technologies.
                  </li>
                  <li>
                    Internet of Things (IoT) Services: Huawei Cloud offers IoT
                    services that enable users to connect, manage, and analyze
                    data from IoT devices. It provides a platform for building
                    IoT applications and managing device connectivity at scale.
                  </li>
                  <li>
                    DevOps and Application Services: Huawei Cloud includes tools
                    and services for application development, deployment, and
                    management. It supports various programming languages,
                    frameworks, and development environments.
                  </li>
                  <li>
                    Hybrid Cloud Solutions: Huawei Cloud provides hybrid cloud
                    solutions that allow users to integrate their on-premises
                    infrastructure with the cloud. This enables seamless data
                    migration, hybrid deployments, and hybrid management.
                  </li>
                  <li>
                    Global Presence: Huawei Cloud has a global footprint with
                    data centers located in multiple regions worldwide. This
                    allows users to deploy their applications and services
                    closer to their target audience, reducing latency and
                    improving performance.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1>Popular Post</h1>
            <div>
              <div>
                <div>
                  <TechLink />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Huawei;
