import React from "react";
import CompLink2 from "../component/ComLink2";
import EventComp from "../component/EventComp";
import Events from "../json/Event";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Event = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const tag = searchParams.get("tag");

  const [eventFilter, setEventFilter] = React.useState(Events);
  const onTagClick = (tag) => {
    navigate(`/event?tag=${tag}`);
  };

  const onPostClick = (id) => {
    navigate(`/event?id=${id}`);
  };

  React.useEffect(() => {
    if (id) {
      const res = Events.filter((ev) => ev.id == id);
      setEventFilter(res);
    } else if (tag) {
      const res = Events.filter((ev) => ev.tags.includes(tag));
      setEventFilter(res);
    }
  }, [id, tag]);
  return (
    <div>
      <div
        className="position-relative"
        // style={{
        //   backgroundImage: "url(images/home/cover.jpg)",
        //   objectFit: "cover",
        //   backgroundSize: "cover",
        // }}
      >
        <img
          src="images/events/cover.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row"
          style={{
            paddingTop: "200px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div className="col-lg-9" style={{ zIndex: "99" }}>
            <div className="pt-5 bg-white">
              <div>
                <h1
                  className=" my-0"
                  style={{
                    textAlign: "center",
                    fontSize: "46px",
                    backgroundColor: "white",
                  }}
                >
                  <b>Events</b>
                </h1>
                <div>
                  {/* <EventComp
                    header="aa"
                    image="/images/events/event1.jpg"
                    id="a"
                    date="a"
                    detail="a"
                  /> */}

                  {eventFilter?.map((item) => {
                    console.log(item);
                    return (
                      <EventComp
                        header={item.header}
                        image={item.image}
                        id={item.id}
                        date={item.date}
                        detail={item.detail}
                        tag={item.tags}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 px-5" style={{ marginBlockStart: "250px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "28px" }}>
              Up Coming event
            </h1>

            <p
              className="m-0 p-2 px-3 mb-2 mt-3"
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
              }}
            >
              22 March 2023, at Best Western Nada Don Mueang Airport
            </p>
            <p
              className="m-0 p-2 px-3 mb-2"
              style={{
                backgroundColor: "#fff",
                borderRadius: "25px",
              }}
            >
              23 - 25 Nov 2022, at Hall 7, Impact Exhibition Center,Bangkok,
              Thailand
            </p>

            <h1 className="mt-5">Event Post</h1>
            <div>
              <CompLink2
                image="/images/events/event1.jpg"
                detail="Arctica Meetup’23"
                href={() => onPostClick("1")}
              />
              <CompLink2
                image="/images/events/event2.jpg"
                detail="DigiTech Asean Thailand 2022"
                href={() => onPostClick("2")}
              />
            </div>

            <h1>Popular Tags</h1>
            <div>
              <a
                href="#"
                style={{
                  color: "black",
                  textDecoration: "none",
                  marginInlineEnd: "20px",
                }}
                onClick={() => onTagClick("All")}
              >
                All
              </a>

              <a
                href="#"
                style={{
                  color: "black",
                  textDecoration: "none",
                  marginInlineEnd: "20px",
                }}
                onClick={() => onTagClick("Arctica")}
              >
                Arctica Meetup’23
              </a>
              <br></br>
              <a
                href="#"
                style={{
                  color: "black",
                  textDecoration: "none",
                  marginInlineEnd: "20px",
                }}
                onClick={() => onTagClick("DigiTech")}
              >
                DigiTech Asean Thailand 2022 Booth E14
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
