import React from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import AutoSlide2 from "../component/autoslide2";
// import EventComp from "../component/EventComp";
// import Events from "../json/Event";

const Careers = () => {
  const { hash } = useLocation();
  const DevOpsRef = useRef(null);
  const MarketingRef = useRef(null);
  const BenefitRef = useRef(null);
  useEffect(() => {
    if (hash) {
      const allHash = [
        { id: "DevOps", ref: DevOpsRef },
        { id: "Marketing", ref: MarketingRef },
        { id: "Benefit", ref: BenefitRef },
      ];

      const focusHash = allHash.find(
        (item) => item.id === hash.replace("#", "")
      );

      setTimeout(() => {
        if (focusHash) {
          focusHash.ref.current.scrollIntoView({
            behavior: "smooth",
            inline: "nearest",
          });
        }
      }, 1000);
    }
  }, [hash]);

  return (
    <div>
      <div className="position-relative">
        <img
          src="images/careers/cover.jpg"
          alt="Home"
          className="position-absolute"
          style={{
            width: "100%",
            margin: "auto",
            height: "400px",
            objectFit: "cover",
          }}
        />

        <div
          className="row"
          style={{
            paddingTop: "300px",
            paddingInline: "70px",
            paddingBlockEnd: "100px",
          }}
        >
          <div
            className="col-12 bg-white pt-5 pb-3 px-5"
            style={{ zIndex: "99" }}
            ref={DevOpsRef}
          >
            <h1>
              <b>DevOps Engineer (18k - 80k)</b>
            </h1>
          </div>
          <div
            className="col-lg-4 bg-white ps-5 d-flex align-items-center"
            style={{ zIndex: "9999" }}
          >
            <img src="images/careers/person1.jpg" style={{ width: "100%" }} />
          </div>
          <div className="col-lg-8 bg-white">
            <div
              style={{
                paddingInline: "10px 50px",
              }}
            >
              <br></br>
              <p style={{ fontSize: "18px" }}>Qualification :</p>
              <ul style={{ fontSize: "16px" }}>
                <li>New graduate are welcome.</li>
                <li>Bachelor's degree in any fields.</li>
              </ul>
              <p style={{ fontSize: "18px" }}>Responsibilities:</p>
              <ul style={{ fontSize: "16px" }}>
                <li>
                  Ensure that deployment automation should display a high degree
                  of repeatability, traceability, and ease of use
                </li>
                <li>
                  Responsible for sharing, advising, and educating others on
                  sound design principles related to reliable operations and
                  deployment of our software
                </li>
                <li>
                  Work closely with members of the product, development, QA, and
                  infrastructure teams to facilitate top-notch delivery of our
                  products on a regular cadence
                </li>
                <li>
                  Contribute ideas and research (technological and product) to
                  enhance the overall service experience
                </li>
                <li>
                  Manage infrastructure using the Infrastructure as Code (IaC)
                  approach
                </li>
                <li>Troubleshoot incidents and perform root cause analysis</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-7 bg-white pt-5">
            <div
              className="col-12 bg-white pt-4 px-5"
              style={{ zIndex: "9999" }}
              ref={MarketingRef}
            >
              <h1>
                <b>Marketing Admin (18k - 22k)</b>
              </h1>
            </div>
            <div
              style={{
                paddingInline: "50px 0",
              }}
            >
              <br></br>
              <p style={{ fontSize: "18px" }}>Qualification :</p>
              <ul className="font-th" style={{ fontSize: "16px" }}>
                <li>ปวส. - ปริญญาตรี</li>
                <li>
                  มีความสามารถในการใช้ Powerpoint/Canva หรือโปรแกรมอื่นๆ
                  ในการทำกราฟฟิคดีไซน์
                </li>
              </ul>
              <p style={{ fontSize: "18px" }}>Responsibilities:</p>
              <ul className="font-th" style={{ fontSize: "16px" }}>
                <li>ดูแลเฟสบุ๊คเพจของบริษัท</li>
                <li>
                  ออกแบบกราฟฟิค ทำ Presentation
                  เพื่อสนับสนุนการทำงานของทีมฝ่ายขาย
                </li>
                <li>รับผิดชอบงานด้านเอกสารทั่วไป</li>
                <li>นำเสนอข้อมูลการทำการตลาดเพื่อขยายฐานลูกค้า</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 bg-white pt-5 pe-5 d-flex align-items-center">
            <img src="images/careers/person2.jpg" style={{ width: "100%" }} />
          </div>
          <div className="col-lg-7 bg-white pt-4 px-5 pb-5" ref={BenefitRef}>
            <h1>
              <b>Benefit</b>
            </h1>
            <ul
              className="font-th"
              style={{ fontSize: "16px", paddingInline: "50px" }}
            >
              <li>วันลาพักร้อน 9 วันต่อปี</li>
              <li>วันลากิจ 3 วันต่อปี</li>
              <li>ประกันสังคม</li>
              <li>เสื้อทีม</li>
              <li>งานเลี้ยงปีใหม่</li>
              <li>Work from anywhere ตามตำแหน่งงาน</li>
            </ul>
          </div>
          <div className="col-lg-5 bg-white d-flex align-items-end pb-5">
            <div
              style={{
                backgroundColor: "#ededed",
                borderRadius: "15px",
                padding: "20px 50px 20px 20px",
                maxWidth: "360px",
                margin: "auto",
              }}
            >
              <p className="m-0">
                <b>ส่ง Resume' มาที่</b>
              </p>
              <br></br>
              <p className="m-0">คุณต้า</p>
              <p className="m-0">keerata.sa@acrtica.co.th</p>
              <p className="m-0">Tel. 098-3679224</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
